<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
    <side-bar></side-bar>
    <!-- partial -->
    <div class="main-panel">
        <div class="content-wrapper">
          <div class="page-header">
            <h4 class="page-title">Currencies</h4>
          </div>
          <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-lg-4 mb-lg-0 mb-2">
                              <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchmedicine" id="searchmedicine" autocomplete="off" v-on:keyup="getCurrenciesAPICALL(1)">
                          </div>
                          <div class="col-lg-4 mb-lg-0 mb-2"></div>
                          <div class="col-lg-2 mb-lg-0 mb-2">
                              <button type="button" class="col-lg-2 btn btn-gradient-primary" @click="insertCurrency" hidden>Add Currency</button>
                          </div>
                          <div class="col-lg-2 mb-lg-0 mb-2">
                                <multiselect
                                    v-model="filter_status"
                                    label = "name"
                                    :options="filter_status_list"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true" placeholder=""
                                    :preselect-first="true"
                                    :showLabels="false"
                                    :allowEmpty="false"
                                    placeholder="Status"
                                    @input=getCurrenciesAPICALL(1)
                                /></multiselect>
                          </div>
                      </div>
                    <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Symbol</th>
                              <th>Code</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(obj,i) in currencies" :key="i">
                                <td>{{i + 1}}</td>
                                <td v-html="obj.currency_symbol"></td>
                                <td>{{obj.currency_code}}</td>
                                <td><label v-bind:class="{
                                        'badge badge-success': obj.currency_status === 'Active',
                                        'badge badge-danger': obj.currency_status === 'Inactive'}"
                                        @click="updateCurrencyStatus(obj, i)"
                                        >
                                  {{obj.currency_status}}
                                  </label>
                                </td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <div v-if="is_API_call_running" class="text-center mt-5">
                      <b-spinner label=""></b-spinner>
                    </div>
                    <div class="row" v-if="!is_API_call_running && currencies.length == 0" >
                        <div class="col-lg-12 text-center">
                          <br/><br/><br/>
                          <small class="text-muted">{{ errorMessage }}</small>
                          <br/><br/><br/>
                        </div>
                    </div>
                    <div class="b-pagination-outer" v-if="listPages > 1">
                      <ul id="border-pagination">
                        <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                        <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getCurrenciesAPICALL(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                        <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                </div>
            </div>
         </div>
    </div>
  </div>
  <SubscriptionDetailsModel ref="subscriptionDetailsComponent"/>
</div>
</template>
  <script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"
import Swal from 'sweetalert2'
import DateRangePicker from 'vue2-daterange-picker'
import SubscriptionDetailsModel from "../model/subscription_plan_details"

export default {
    name:'currencies',
    title: string.PAGE_TITLE_CURRENCIES,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DateRangePicker,
      SubscriptionDetailsModel,
    },
    data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)
      return {
          moment,
          currencies:[],
          is_API_call_running : false,
          listPages:0,
          activePage:1,
          searchtext:'',
          myTimer:null,
          errorMessage : 'No Data Found.',
          filter_status_list:[
                            {'id':'All','name':'All'},
                            {'id':'Active','name':'Active'},
                            {'id':'Inactive','name':'Inactive'},
                        ],
          filter_status: {'id':'All','name':'All'},
        dateRange:{
            startDate: today,
            endDate: today
        },
        MAXDATE:maxDate,
      }
    },
    mounted() {
      this.getCurrenciesAPICALL(this.activePage);
    },
    methods:{
      ...mapActions("hospital",["getCurrencies", "updateCurrencyStatusAPI", "insertCurrencyAPI"]),
  
      getCurrenciesAPICALL(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
          this.is_API_call_running = true;
            this.currencies = [];
            var bodyFormData = new FormData();
            bodyFormData.append('page', page);
            bodyFormData.append('filter_value', this.searchtext);
            bodyFormData.append('filter_status', this.filter_status.id);
            this.getCurrencies(bodyFormData).then((response) => {
              this.is_API_call_running = false;
              if (response.response_code == 200) {
                this.currencies = response.data;
                this.listPages = response.no_of_pages;
              }else{
                this.errorMessage = response.message;
              }
            });
        }, 500)
      },

      updateCurrencyStatus(obj, index){
        var bodyFormData = new FormData();
        if(obj.currency_status == "Active") {
            bodyFormData.append('currency_status', "Inactive");
        } else {
            bodyFormData.append('currency_status', "Active");
        }
        bodyFormData.append('currency_id', obj.id);
        this.updateCurrencyStatusAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                if(obj.currency_status == "Active") {
                    obj.currency_status = "Inactive"
                } else {
                    obj.currency_status = "Active"
                }
                this.currencies.splice(index, 1, obj);
            } else {
                this.errorMessage = response.message;
            }
        });
      },

      insertCurrency(obj, index){
        var bodyFormData = new FormData();
        this.insertCurrencyAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.getCurrenciesAPICALL(1);
            } else {
                this.errorMessage = response.message;
            }
        });
      },

          previewClick(){
            this.activePage--;
            if(this.activePage >= 1){
              this.getCurrenciesAPICALL(this.activePage);
            }else{
              this.activePage = 1;
            }
          },

          nextClick(){
            this.activePage++;
            if(this.activePage <= this.listPages){
              this.getCurrenciesAPICALL(this.activePage);
            }else{
              this.activePage = this.listPages;
            }
          },
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
